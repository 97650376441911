import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import Question from './Question';
import Home from './Home';
import Modal from './Modal';
import ImageModal from './ImageModal';
import { execScript, parseForm } from './utils';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const flow = urlParams.get('flow') || 'flow.json';
  const logo = 'logo.png';

  const viewportWidth = urlParams.get('viewportWidth');
  const viewportHeight = urlParams.get('viewportHeight');
  const questionId = urlParams.get('question');


  if (viewportWidth && viewportHeight) {
    const appRoot = document.getElementById('root');
    appRoot.style.width = `${viewportWidth}px`;
    appRoot.style.height = `${viewportHeight}px`;
  }
  const [data, setData] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [result, setResult] = useState(null);
  const [language, setLanguage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [main, setMain] = useState(true);
  const [hiddenButton, setHiddenButton] = useState(true);
  const [selected, setSelected] = useState(null);
  const [textButton, setTextButton] = useState('');
  const [userPath, setUserPath] = useState([]);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formFields, setFormFields] = useState([]);

  const divRef = useRef([]);
  const questionHistory = useRef([]);
  const currentIndex = useRef(0);

  const getBasename = () => {
    const pathName = window.location.pathname;
    const firstSlashIndex = pathName.indexOf('/', 1);
    if (firstSlashIndex !== -1) {
      return pathName.substring(0, firstSlashIndex);
    }
    return '/';
  };

  const basename = getBasename();

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const handleOptionClick = (option) => {
    setSelected(option);
    if (option.form) {
      setFormFields(parseForm(option.form, language));
    } else {
      setFormFields([]);
    }
  };

  const resetFlow = () => {
    localStorage.clear();
    sessionStorage.clear();
    setCurrentQuestion(data.questions[0]);
    setSelected(null);
    setUserPath([]);
    questionHistory.current = [];
    currentIndex.current = 0;
    setResult(null);
    setIsFormModalOpen(false);
    setIsImageModalOpen(false);
    setMain(true);
  };

  const nextQuestionOrResult = () => {
    if (selected) {
      if ('nextQuestion' in selected) {
        const nextQuestion = data.questions.find(q => q.id === selected.nextQuestion);
        setCurrentQuestion(nextQuestion);
        setSelected(null);
        setMain(false);
        const scriptToExecute = selected.script && (selected.script[language] || Object.values(selected.script)[0]);
        if (scriptToExecute) {
          execScript(scriptToExecute);
        }
      } else if ('result' in selected) {
          const scriptToExecute = selected.script && (selected.script[language] || Object.values(selected.script)[0]);
          if (scriptToExecute) {
            execScript(scriptToExecute);
          }
        const selectedResult = data.results.find(r => r.id === selected.result);
        if (selectedResult) {
          if (selectedResult.form) {
            setFormFields(parseForm(selectedResult.form, language));
            setResult(null);
            setIsFormModalOpen(true);
          } else {
            setResult(selectedResult);
            setCurrentQuestion(null);
            setSelected(null);
            setMain(false);
          }
        } else {
          console.error("Result not found");
        }
      }
      setUserPath([...userPath, { questionId: currentQuestion.id, selectedOption: selected }]);
      questionHistory.current.push(currentQuestion);
      currentIndex.current += 1;
    }
  };

  const handleFormSubmit = (formData) => {
    Object.entries(formData).forEach(([key, value]) => {
      sessionStorage.setItem(key, value);
    });
    setIsFormModalOpen(false);
    nextQuestionOrResult();
  };

  const handleImageClick = (imageSrc) => {
    if (imageSrc) {
      setSelectedImage(imageSrc);
      setIsImageModalOpen(true);
    }
  };

  const renderVideoEmbed = (url) => {
    let embedUrl;
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const videoId = url.split('v=')[1] || url.split('/').pop();
      embedUrl = `https://www.youtube.com/embed/${videoId}`;
    }
    return (
      <iframe
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded video"
        className="embedded-media"
      ></iframe>
    );
  };

  const getMediaContent = (mediaType, mediaData) => {
    if (!mediaData) return null;

    const mediaKeys = Object.keys(mediaData);
    if (mediaKeys.length === 1) {
      return mediaData[mediaKeys[0]];
    } else {
      return mediaData[language];
    }
  };

  useEffect(() => {
    fetch(flow)
      .then(response => response.json())
      .then(data => {
        setData(data);
        const defaultLanguage = urlParams.get('defaultLanguage') || Object.keys(data.languages)[0] || 'ca';
        setLanguage(defaultLanguage);
	const initialQuestion = questionId
	  ? data.questions.find(q => q.id === questionId)
	  : data.questions[0];

	if (!initialQuestion) {
	  // Manejar el caso donde el questionId no es válido
	  console.error(`Question with ID ${questionId} not found.`);
	  setMain(true); // Volver a la página de inicio o mostrar un mensaje de error
	} else {
	  setCurrentQuestion(initialQuestion);
	  setMain(questionId ? false : true);
	}
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setTextButton(selected ? data?.home?.sendButton[language] : '');
    setHiddenButton(!selected);
  }, [selected]);
  
  useEffect(() => {
    if (result) {
      const scriptToExecute = result.script && (result.script[language] || Object.values(result.script)[0]);
      if (scriptToExecute) {
        execScript(scriptToExecute);
      }
    }
  }, [result, language]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const goToTest = () => {
    setMain(false);
  };

  if (main) {
    return (
      <Router basename={basename}>
        <div className="welcome">
          <Home
            home={data.home}
            langs={data.languages}
            language={language}
            goToTest={goToTest}
            handleLanguageChange={handleLanguageChange}
            logo={logo}
            getMediaContent={getMediaContent}
            renderVideoEmbed={renderVideoEmbed}
            handleImageClick={handleImageClick}
          />
        </div>
      </Router>
    );
  } else if (result && !isFormModalOpen) {
    return (
      <Router basename={basename}>
        <div className="welcome">
          <div className="header">
            <img src={`/${logo}`} data-qa="brand-logo" className="logo" alt="" />
          </div>
          <div className="principal">
            <div className="welcome_content">
              <div className="description" dangerouslySetInnerHTML={{ __html: result.text[language] }}></div>
              <br />
              <div>
                {userPath.map((item, index) =>
                  item.selectedOption.documentation ? (
                    <div key={index}>
                      <div dangerouslySetInnerHTML={{ __html: `<strong>${item.selectedOption.documentation[language].replace(/\n/g, '<br />')}</strong>` }}></div>
                    </div>
                  ) : null
                )}
              </div>
              <br />
              {result.image && <img src={getMediaContent('image', result.image)} alt="Image" className="embedded-media" onClick={() => handleImageClick(getMediaContent('image', result.image))} />}
              {result.video && renderVideoEmbed(result.video)}
              {result.link[language] && (
                <a onClick={() => {
                  const link = decodeURIComponent(result.link[language]) || '#';
                  window.open(link, '_blank', 'noopener,noreferrer');
                }}>
                  <button className="button_start" dangerouslySetInnerHTML={{ __html: result.button[language] }}></button>
                </a>
              )}
              <a href=""><button className="button_start" onClick={resetFlow}>Reset</button></a>
            </div>
          </div>
        </div>
      </Router>
    );
  } else {
    return (
      <Router basename={basename}>
        <React.Fragment>
          <div className="header">
            <img src={`/${logo}`} data-qa="brand-logo" className="logo" alt="" />
          </div>
          <Question
            question={currentQuestion}
            handleOptionClick={handleOptionClick}
            language={language}
            divRef={divRef}
            hiddenButton={hiddenButton}
            textButton={textButton}
            nextQuestionOrResult={nextQuestionOrResult}
            selected={selected}
            resetFlow={resetFlow}
            handleFormSubmit={handleFormSubmit}
            getMediaContent={getMediaContent}
            renderVideoEmbed={renderVideoEmbed}
            handleImageClick={handleImageClick}
          />
          {/* Form Modal */}
          <Modal
            isOpen={isFormModalOpen}
            onClose={() => setIsFormModalOpen(false)}
            onSubmit={handleFormSubmit}
            formFields={formFields}
            language={language}
            formButton={textButton}
          />
          {/* Image Modal */}
          <ImageModal
            imageSrc={selectedImage}
            isOpen={isImageModalOpen}
            onClose={() => setIsImageModalOpen(false)}
          />
        </React.Fragment>
      </Router>
    );
  }
}

export default App;

